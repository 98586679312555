import { db } from '@flowby/shared-firebase'
import { useParams } from 'react-router-dom'
import QueueKioskView from '../components/Kiosk/QueueKioskView'
import Error from '../components/shared/Error'
import Loader from '../components/shared/Loader'
import { useDocData } from '../libs/firebaseHooks'
import { hideStatusBar } from '../libs/native'

export default function KioskPage() {
  hideStatusBar()
  const params = useParams()
  const queryParams = new URLSearchParams(window.location.search)
  const store = params.store as string
  const queue = params.queue as string
  const printerPortName = (queryParams.get('printer') as string | null) || null

  const [queueData, queueLoading] = useDocData(db.getQueueRef(store, queue), [store, queue])

  if (queueLoading) {
    return <Loader />
  }

  if (!queueData) {
    return <Error />
  }

  return <QueueKioskView
    store={store}
    queue={queue}
    queueData={queueData}
    printerPortName={printerPortName}
  />
}
