import { AnalyticsData } from '@flowby/shared-firebase'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Created Queue Numbers',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false,
      },
      stacked: true,
    },
  },
}

const hours = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
]

const getHour = (dateString: string) => {
  const date = new Date(dateString)
  const day = date.getHours()
  return hours[day]
}

export function HourChart({ analyticsData }: { analyticsData: AnalyticsData[] }) {
  const labels = hours
  const hourData = labels.map(hour => {
    return analyticsData.reduce(
      (acc, curr) => {
        const currHour = getHour(curr.when.value)
        if (currHour === hour) {
          if (curr.action === 'create') {
            acc['totalCount'] += 1
          }
          if (curr.action === 'create' && curr.manual === true) {
            acc['manualCount'] += 1
          }
          if (curr.action === 'create' && curr.kiosk === true) {
            acc['kioskCount'] += 1
          }
          if (curr.action === 'sms_sent') {
            acc['smsSentCount'] += 1
          }
          if (curr.action === 'input_data') {
            acc['dataInputCount'] += 1
          }
          return acc
        }
        return acc
      },
      {
        totalCount: 0,
        manualCount: 0,
        kioskCount: 0,
        smsSentCount: 0,
        dataInputCount: 0,
      },
    )
  })

  const data = {
    labels,
    datasets: [
      {
        label: 'Digital Numbers',
        data: hourData.map(d => d.totalCount - d.manualCount),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Manual Numbers',
        data: hourData.map(d => d.manualCount),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0',
      },
      {
        label: 'SMS Sent',
        data: hourData.map(d => d.smsSentCount),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 1',
      },
      {
        label: 'Data Input',
        data: hourData.map(d => d.dataInputCount),
        backgroundColor: 'rgb(255, 207, 86)',
        stack: 'Stack 2',
      },
    ],
  }
  return <Bar options={options} data={data} />
}
