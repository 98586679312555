import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Chart as ReactChartJS } from 'react-chartjs-2'
import { MappedAnalyticsData } from './types'
import { filterUndefined, median } from './utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  LineController,
  BarController,
  Title,
  Tooltip,
  Legend
)

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Created Queue Numbers',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        display: false
      },
    },
  },
}

export function Chart({ dates, mappedAnalyticsData, waitTimes, adjustMedianWaitTime }: {
  dates: string[],
  mappedAnalyticsData: MappedAnalyticsData[],
  waitTimes: { dateTime: Date, date: string, wait: number }[] | undefined,
  adjustMedianWaitTime: boolean
}) {
  const dateWaitTimes = waitTimes && dates.map((date) => {
    const wts = filterUndefined(waitTimes.map((wt) => {
      return wt.date === date ? wt.wait : undefined
    }))
    if (wts.length > 0) {
      const waitTime = Math.round(median(wts, adjustMedianWaitTime ? 2 * 60 * 60 * 1000 : undefined) / 1000 / 60)
      return waitTime
    } else {
      return 0
    }
  })

  const labels = dates
  const data = {
    labels,
    datasets: [
      {
        yAxisID: 'y',
        label: 'Digital Numbers',
        data: mappedAnalyticsData.map(d => d.totalCount - d.manualCount),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        yAxisID: 'y',
        label: 'Manual Numbers',
        data: mappedAnalyticsData.map(d => d.manualCount),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0',
      },
      {
        yAxisID: 'y',
        label: 'SMS Sent',
        data: mappedAnalyticsData.map(d => d.smsSentCount),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 1',
      },
      {
        yAxisID: 'y',
        label: 'Data Input',
        data: mappedAnalyticsData.map(d => d.dataInputCount),
        backgroundColor: 'rgb(255, 207, 86)',
        stack: 'Stack 2',
      },
      {
        yAxisID: 'y1',
        type: 'line' as const,
        label: adjustMedianWaitTime ? 'Adj. Median Wait Time' : 'Median Wait Time',
        data: dateWaitTimes,
        backgroundColor: 'rgb(143, 131, 195)',
        borderColor: 'rgb(143, 131, 195)',
      },
    ],
  }
  return <ReactChartJS type="bar" options={options} data={data} />
}
