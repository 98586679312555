import { Navigate, useParams } from 'react-router-dom'
import { Outlet } from 'react-router-dom'

export default function StoreLayout({ claimStore, admin }: { claimStore: string; admin: boolean }) {
  const params = useParams()
  const store = params.store as string
  document.title = 'Flowby' // to reset when title changed in queue view

  return claimStore !== store && !admin ? <Navigate to={`/${claimStore}`} /> : <Outlet context={{ store }} />
}
