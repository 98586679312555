import { useEffect, useState } from 'react'
import { AnalyticsData, QueueData } from '@flowby/shared-firebase'
import Loader from '../../shared/Loader'
import { Chart } from './Chart'
import { DayChart } from './DayChart'
import { RangeDatepicker } from './DatePicker'
import { Box, Checkbox, Divider, FormControl, FormHelperText, FormLabel, Select, Stack, Text, Tooltip } from '@chakra-ui/react'
import { Stats } from './Stats'
import { HourChart } from './HourChart'
import { getWaitTimes } from './utils'
import { useToast } from '../../shared/Toast'
import { MappedAnalyticsData } from './types'
import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { db } from '@flowby/shared-firebase'
import { useScreenSize } from '../../../libs/native'

const mapAnalyticsData = (data: AnalyticsData[], dates: string[]) => {
  return dates.map(date => {
    const dateData = data.filter(d => d.date.value === date)
    return dateData.reduce(
      (acc, curr) => {
        if (curr.action === 'create') {
          acc['totalCount'] += 1
        }
        if (curr.action === 'create' && curr.manual === true) {
          acc['manualCount'] += 1
        }
        if (curr.action === 'create' && curr.kiosk === true) {
          acc['kioskCount'] += 1
        }
        if (curr.action === 'sms_sent') {
          acc['smsSentCount'] += 1
        }
        if (curr.action === 'input_data') {
          acc['dataInputCount'] += 1
        }
        return acc
      },
      {
        totalCount: 0,
        manualCount: 0,
        kioskCount: 0,
        smsSentCount: 0,
        dataInputCount: 0,
      },
    )
  })
}

const getDateString = (date: Date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`
}

// get dates between two dates
const getDates = (startDate: string, endDate: string) => {
  const dates: string[] = []
  const currDate = new Date(startDate)
  const end = new Date(endDate)
  while (currDate <= end) {
    const currDateString = currDate.toISOString().split('T')[0]
    if (currDateString) {
      dates.push(currDateString)
    }
    currDate.setUTCDate(currDate.getUTCDate() + 1)
  }
  return dates
}

const getLast30DaysDates: () => [Date, Date] = () => {
  const today = new Date()
  const lastWeek = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)
  return [lastWeek, today]
}

export default function Analytics({ store, queuesData }: { store: string; queuesData: QueueData[] }) {
  const [analyticsData, setAnalyticsData] = useState<AnalyticsData[] | null>(null)
  const [filteredAnalyticsData, setFilteredAnalyticsData] = useState<AnalyticsData[] | null>(null)
  const [mappedAnalyticsData, setMappedAnalyticsData] = useState<MappedAnalyticsData[] | null>(null)
  const [times, setTimes] = useState<{
    queuerId: string,
    date: string,
    dateTime: Date,
    wait: number
  }[] | null>(null)
  const [dateRange, setDateRange] = useState<Date[]>(getLast30DaysDates())
  const [selectedQueue, setSelectedQueue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [adjustMedianWaitTime, setAdjustMedianWaitTime] = useState<boolean>(true)
  const dates = dateRange[0] && dateRange[1] && getDates(getDateString(dateRange[0]), getDateString(dateRange[1]))
  const toast = useToast()

  const getAnalyticsData = async () => {
    if (dateRange[0] && dateRange[1]) {
      setLoading(true)
      try {
        const response = await db.getAnalyticsData({
          startDate: getDateString(dateRange[0]),
          endDate: getDateString(dateRange[1]),
          store: store,
        })
        if (!response.data[0]) {
          throw new Error('No data returned.')
        }
        setLoading(false)
        return response.data[0]
      } catch (e) {
        setLoading(false)
        toast('error', null, 'Analytics/error-fetching data', e)
        return null
      }
    }
    return null
  }

  useEffect(() => {
    if (dateRange.length > 1) {
      setAnalyticsData(null)
      getAnalyticsData().then(analyticsData => {
        setAnalyticsData(analyticsData)
      })
    }
  }, [dateRange])

  useEffect(() => {
    if (dates && dates.length > 0 && analyticsData) {
      const filteredAnalyticsData = selectedQueue === ''
        ? analyticsData
        : analyticsData && analyticsData.filter(dataPoint => dataPoint.queue === selectedQueue)
      const mappedAnalyticsData = mapAnalyticsData(filteredAnalyticsData, dates)
      setFilteredAnalyticsData(filteredAnalyticsData)
      setMappedAnalyticsData(mappedAnalyticsData)
    }
  }, [analyticsData, selectedQueue])

  useEffect(() => {
    if (filteredAnalyticsData) {
      const deletedDataPoints = filteredAnalyticsData.filter((dataPoint) => dataPoint.action === 'delete')
      const createdDataPoints = filteredAnalyticsData.filter((dataPoint) => dataPoint.action === 'create')
      const definedTimes = getWaitTimes(deletedDataPoints, createdDataPoints, adjustMedianWaitTime ? 5000 : undefined)
      setTimes(definedTimes)
    }
  }, [filteredAnalyticsData, adjustMedianWaitTime])

  const today = new Date()
  const screenSize = useScreenSize()
  return (
    <Stack spacing={4} pb={2}>
      <FormControl id="analytics">
        <FormLabel>Options</FormLabel>
        <FormHelperText>
          Select the date range and which queues to view data for below.
          You can also choose to adjust the median wait time by excluding extreme outliers.
        </FormHelperText>
        <Stack spacing={2}>
          <RangeDatepicker selectedDates={dateRange} onDateChange={(dates) => {
            setDateRange(dates)
          }} maxDate={today} />
          <Select
            placeholder="All Queues"
            onChange={e => {
              setSelectedQueue(e.target.value)
            }}
          >
            {queuesData.map(queue => (
              <option key={queue.shortName} value={queue.shortName}>
                {queue.displayName}
              </option>
            ))}
          </Select>
          <Stack direction="row" ml={1}>
            <Checkbox
              isChecked={adjustMedianWaitTime}
              onChange={(e) => setAdjustMedianWaitTime(e.target.checked)}
            >Adjust median wait times</Checkbox>
            <Box>
              <Tooltip
                label='If this option is enabled we will adjust median wait time by removing outliers that are likely not representative. 
                This exludes queuers where one of the following is true:
                (1) The staff has clicked "Next customer" twice within 5 seconds (indicating previously forgetting to click next customer)
                (2) The queuer has been waiting for more than 2 hours (indicating closing the store without closing the queue or forgetting to click next customer for a long time)
                '
                fontSize='md'
              >
                <QuestionOutlineIcon />
              </Tooltip>
            </Box>
          </Stack>
          <Divider pt={2} />
          {(!mappedAnalyticsData || !dates || !times || loading) && <Loader />}
          {screenSize !== 'mobile' && filteredAnalyticsData && mappedAnalyticsData && dates && times && !loading && (
            <Stack py={0} spacing={8}>
              <Box>
                <FormLabel>Period Overview</FormLabel>
                <FormHelperText>
                  This chart shows the overview for all of the dates during the selected period.
                </FormHelperText>
                <Stats
                  mappedAnalyticsData={mappedAnalyticsData}
                  times={times?.map(t => t.wait)}
                  adjustMedianWaitTime={adjustMedianWaitTime}
                />
                <Chart
                  dates={dates}
                  mappedAnalyticsData={mappedAnalyticsData}
                  waitTimes={times}
                  adjustMedianWaitTime={adjustMedianWaitTime}
                />
              </Box>
              <Box>
                <FormLabel>Weekday View</FormLabel>
                <FormHelperText>
                  This chart shows the queue data aggregated by weekday for the selected period. Use it to see which
                  days your store has the most traffic.
                </FormHelperText>
                <DayChart
                  dates={dates}
                  mappedAnalyticsData={mappedAnalyticsData}
                  waitTimes={times}
                  adjustMedianWaitTime={adjustMedianWaitTime}
                />
              </Box>
              <Box>
                <FormLabel>Hour View</FormLabel>
                <FormHelperText>
                  This chart shows the queue data aggregated by hour during the day for the selected period. Use it to
                  see which hours your store has the most traffic.
                </FormHelperText>
                <HourChart analyticsData={filteredAnalyticsData} />
              </Box>
            </Stack>
          )}
          {screenSize === 'mobile' && filteredAnalyticsData && mappedAnalyticsData && dates && times && !loading && (
            <Stack py={4} spacing={8}>
              <Stats
                mappedAnalyticsData={mappedAnalyticsData}
                times={times?.map(t => t.wait)}
                adjustMedianWaitTime={adjustMedianWaitTime}
              />
              <Text fontSize="xl">
                Login to the flowby website on a computer to see a more detailed analytics view.
              </Text>
            </Stack>
          )}
        </Stack>
      </FormControl>
    </Stack>
  )
}
