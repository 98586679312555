import { Chart as ChartJS, CategoryScale, LinearScale, LogarithmicScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
import { Chart as ReactChartJS } from 'react-chartjs-2'
import { MappedAnalyticsData } from './types'
import { filterUndefined, median } from './utils'

ChartJS.register(
  CategoryScale,
  LinearScale,
  LogarithmicScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend)

export const options = {
  plugins: {
    title: {
      display: false,
      text: 'Created Queue Numbers',
    },
  },
  responsive: true,
  interaction: {
    mode: 'index' as const,
    intersect: false,
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    y: {
      grid: {
        display: false,
      },
      stacked: true,
    },
    y1: {
      type: 'linear' as const,
      display: true,
      position: 'right' as const,
      grid: {
        display: false
      },
    },
  },
}

const getWeekDay = (dateString: string) => {
  const date = new Date(dateString)
  const day = date.getDay()
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  return days[day]
}

export function DayChart({ dates, mappedAnalyticsData, waitTimes, adjustMedianWaitTime }: {
  dates: string[],
  mappedAnalyticsData: MappedAnalyticsData[],
  waitTimes: { dateTime: Date, date: string, wait: number }[] | undefined,
  adjustMedianWaitTime: boolean
}) {
  const labels = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
  const dayWaitTimes = waitTimes && labels.map((_day, i) => {
    const wts = filterUndefined(waitTimes.map((wt) => {
      // getUTCDay() starts from sunday, so subtract 1 and move -1 to 6, representing sunday
      const utcWeekday = wt.dateTime.getUTCDay() - 1 >= 0 ? wt.dateTime.getUTCDay() - 1 : 6
      return utcWeekday === i ? wt.wait : undefined
    }))
    if (wts.length > 0) {
      return Math.round(median(wts, adjustMedianWaitTime ? 2 * 60 * 60 * 1000 : undefined) / 1000 / 60)
    } else {
      return 0
    }
  })
  const dayIndices = dates.map(d => getWeekDay(d))
  const dayData = labels.map(day => {
    return dayIndices.reduce(
      (acc, curr, currIndex) => {
        const mappedAnalyticsDataIndex = mappedAnalyticsData[currIndex]
        if (curr === day && mappedAnalyticsDataIndex) {
          acc.totalCount += mappedAnalyticsDataIndex.totalCount
          acc.manualCount += mappedAnalyticsDataIndex.manualCount
          acc.smsSentCount += mappedAnalyticsDataIndex.smsSentCount
          acc.kioskCount += mappedAnalyticsDataIndex.kioskCount
          acc.dataInputCount += mappedAnalyticsDataIndex.dataInputCount
        }
        return acc
      },
      {
        totalCount: 0,
        manualCount: 0,
        kioskCount: 0,
        smsSentCount: 0,
        dataInputCount: 0,
      },
    )
  })

  const data = {
    labels,
    datasets: [
      {
        label: 'Digital Numbers',
        data: dayData.map(d => d.totalCount - d.manualCount),
        backgroundColor: 'rgb(255, 99, 132)',
        stack: 'Stack 0',
      },
      {
        label: 'Manual Numbers',
        data: dayData.map(d => d.manualCount),
        backgroundColor: 'rgb(75, 192, 192)',
        stack: 'Stack 0',
      },
      {
        label: 'SMS Sent',
        data: dayData.map(d => d.smsSentCount),
        backgroundColor: 'rgb(53, 162, 235)',
        stack: 'Stack 1',
      },
      {
        label: 'Data Input',
        data: dayData.map(d => d.dataInputCount),
        backgroundColor: 'rgb(255, 207, 86)',
        stack: 'Stack 2',
      },
      {
        yAxisID: 'y1',
        type: 'line' as const,
        label: adjustMedianWaitTime ? 'Adj. Median Wait Time' : 'Median Wait Time',
        data: dayWaitTimes,
        backgroundColor: 'rgb(143, 131, 195)',
        borderColor: 'rgb(143, 131, 195)',
      },
    ],
  }
  return <ReactChartJS type="bar" options={options} data={data} />
}
