import { Stat, StatLabel, StatNumber, StatGroup, Box } from '@chakra-ui/react'
import { useScreenSize } from '../../../libs/native'
import { MappedAnalyticsData } from './types'
import { median } from './utils'

export function Stats({ mappedAnalyticsData, times, adjustMedianWaitTime }: {
  mappedAnalyticsData: MappedAnalyticsData[],
  times: number[] | undefined,
  adjustMedianWaitTime: boolean
}) {
  const totalNumbers = mappedAnalyticsData.reduce(
    (acc, curr) => {
      acc['totalCount'] += curr.totalCount
      acc['manualCount'] += curr.manualCount
      acc['kioskCount'] += curr.kioskCount
      acc['smsSentCount'] += curr.smsSentCount
      acc['dataInputCount'] += curr.dataInputCount
      return acc
    },
    {
      totalCount: 0,
      manualCount: 0,
      kioskCount: 0,
      smsSentCount: 0,
      dataInputCount: 0
    },
  )
  const screenSize = useScreenSize()
  return (
    <Box p={4} mb={2} borderWidth="1px" textAlign="center" borderRadius="md">
      {screenSize !== 'mobile' && (
        <StatGroup>
          <Stat>
            <StatLabel>Digital Numbers</StatLabel>
            <StatNumber>{totalNumbers.totalCount - totalNumbers.manualCount}</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Manual Numbers</StatLabel>
            <StatNumber>{totalNumbers.manualCount}</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>SMS Sent</StatLabel>
            <StatNumber>{totalNumbers.smsSentCount}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Data Input</StatLabel>
            <StatNumber>{totalNumbers.dataInputCount}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>{adjustMedianWaitTime ? 'Adj. Median Wait Time' : 'Median Wait Time'}</StatLabel>
            <StatNumber>{times && times.length > 0 ? `${Math.round(median(times, adjustMedianWaitTime ? 2 * 60 * 60 * 1000 : undefined) / 1000 / 60)} min` : '-'}</StatNumber>
          </Stat>
        </StatGroup>
      )}
      {screenSize === 'mobile' && (
        <Box>
          <Stat>
            <StatLabel>Digital Numbers</StatLabel>
            <StatNumber>{totalNumbers.totalCount - totalNumbers.manualCount}</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>Manual Numbers</StatLabel>
            <StatNumber>{totalNumbers.manualCount}</StatNumber>
          </Stat>

          <Stat>
            <StatLabel>SMS Sent</StatLabel>
            <StatNumber>{totalNumbers.smsSentCount}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>Data Input</StatLabel>
            <StatNumber>{totalNumbers.dataInputCount}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel>{adjustMedianWaitTime ? 'Adj. Median Wait Time' : 'Median Wait Time'}</StatLabel>
            <StatNumber>{times && times.length > 0 ? `${Math.round(median(times, adjustMedianWaitTime ? 2 * 60 * 60 * 1000 : undefined) / 1000 / 60)} min` : '-'}</StatNumber>
          </Stat>
        </Box>
      )}
    </Box>
  )
}
