import { AnalyticsData } from "@flowby/shared-firebase"

export function median(numbers: number[], maxNumber?: number): number {
  if (maxNumber) {
    numbers = numbers.filter((n) => n <= maxNumber)
  }
  const sorted = numbers.sort((a, b) => a - b)
  const middle = Math.floor(sorted.length / 2)
  const sortedMiddle = sorted[middle]
  if (!sortedMiddle) {
    return 0
  }

  if (sorted.length % 2 === 0) {
    const sortedMiddleMinusOne = sorted[middle - 1]
    if (sortedMiddleMinusOne) {
      return (sortedMiddleMinusOne + sortedMiddle) / 2
    }
  }

  return sortedMiddle
}

// So called type guard used to filter arrays for undefined and give correct type out
export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined
}

// This type guard is used to filter arrays and give correct type out, if only isDefined used it does
// not account for the face that the array can be empty
export function filterUndefined<T>(array: (T | undefined)[]): T[] {
  return array.filter(isDefined)
}

export function findClosestDataPointForSameQueue(
  dataPoints: AnalyticsData[],
  compareDateTime: Date
) {
  const veryOldDate = new Date('1970-01-01') // start with a date far away
  const dateTime = compareDateTime.valueOf()
  const res = dataPoints.reduce((acc, curr) => {
    const currDate = new Date(curr.when.value)
    const currTime = currDate.valueOf()
    const accTime = acc.valueOf()
    if (dateTime !== currTime) { // should not compare to itself
      if (Math.abs(dateTime - currTime) < Math.abs(dateTime - accTime)) {
        return currDate
      }
    }
    return acc
  }, veryOldDate)
  return res
}

export function getWaitTimes(
  deletedDataPoints: AnalyticsData[], 
  createdDataPoints: AnalyticsData[],
  minWaitTime?: number // used to filter out data points that are too short, and likely due to queue mismanagement
) {
  const times = deletedDataPoints.map((deletedDataPoint) => {
    const deletedTime = new Date(deletedDataPoint.when.value)
    // It is important to filter the below value as much as possible for performance reasons
    // One limitation with the optimization below is if there's a nearest data point on another date
    // (for example if queue numbers taken around midnight UTC) it will not be accounted for
    // However, this is a rare case and the performance gains are worth it
    const filteredDeletedDataPoints = deletedDataPoints
      .filter((d) => d.date.value === deletedDataPoint.date.value && d.queue === deletedDataPoint.queue)
    const closestDeletedDataPoint = findClosestDataPointForSameQueue(
      filteredDeletedDataPoints,
      deletedTime
    )
    if (minWaitTime && Math.abs(closestDeletedDataPoint.valueOf() - deletedTime.valueOf()) < minWaitTime) {
      return undefined
    }
    const created = createdDataPoints.map((dataPoint) => {
      if (deletedDataPoint.queuer_id === dataPoint.queuer_id) {
        return {
          date: dataPoint.date.value,
          dateTime: new Date(dataPoint.when.value),
        }
      }
    }).filter(isDefined)[0] // due to [0] this will be undefined if no data returned

    if (created) {
      return {
        queuerId: deletedDataPoint.queuer_id,
        date: created.date,
        dateTime: created.dateTime,
        wait: deletedTime.valueOf() - created.dateTime.valueOf()
      }
    }
  })
  return filterUndefined(times)
}