import { useState, useEffect } from 'react'
import QRCode from '../../shared/QRCode'
import {
  Box,
  Stack,
  Container,
  Button,
  FormControl,
  FormLabel,
  FormHelperText,
  Text,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
} from '@chakra-ui/react'
import { db, QueueData, GroupData } from '@flowby/shared-firebase'
import ConfirmAlert from '../../shared/ConfirmAlert'
import FormikForm from '../../shared/FormikForm'
import { useToast } from '../../shared/Toast'
import { useDocData } from '../../../libs/firebaseHooks'

export default function EditGroupForm({
  store,
  group,
  queuesData,
  groupsData,
  onFinish,
}: {
  store: string
  group: string
  queuesData: QueueData[]
  groupsData: GroupData[]
  onFinish: () => void
}) {
  const [groupDisplayName, setGroupDisplayName] = useState('')
  const [deleteGroupLoading, setDeleteGroupLoading] = useState(false)
  const deleteGroupDisclosure = useDisclosure()
  const toast = useToast()
  const [addQueueSelect, setAddQueueSelect] = useState<string | null>(null)
  const [groupData, groupLoading, groupError] = useDocData(db.getGroupRef(store, group), [store, group])
  const queuesInAGroup = groupsData.reduce<string[]>((acc, curr) => {
    return acc.concat(curr.queues)
  }, [])
  const queuesNotInAGroup = queuesData.filter(queue => {
    return !queuesInAGroup.includes(queue.shortName)
  })
  const queuesInGroup = groupData
    ? queuesData.filter(queue => {
      return groupData.queues.includes(queue.shortName)
    })
    : []
  useEffect(() => {
    if (!groupLoading && !groupError && groupDisplayName === '' && groupData) {
      setGroupDisplayName(groupData.displayName)
    }
  }, [groupData])

  useEffect(() => {
    if (!addQueueSelect) {
      setAddQueueSelect(queuesNotInAGroup.length > 0 && queuesNotInAGroup[0] ? queuesNotInAGroup[0].shortName : null)
    }
  })

  const editGroupDisplayName = async (data: { [key: string]: any }) => {
    try {
      if (!data.groupDisplayName) {
        throw new Error('Missing group display name')
      }
      await db.updateGroup(store, group, { displayName: data.groupDisplayName })
      toast('success', 'Group name updated.')
    } catch (e) {
      toast('error', null, 'EditGroupForm/edit-group-display-name-error', e)
    }
  }

  const onRemoveQueue = async (queue: string) => {
    try {
      await db.removeQueueFromGroup(store, group, queue)
      setAddQueueSelect(null)
    } catch (e) {
      toast('error', null, 'EditGroupForm/remove-queue-error', e)
    }
  }

  const onAddQueue = async () => {
    if (addQueueSelect) {
      try {
        await db.addQueueToGroup(store, group, addQueueSelect)
        setAddQueueSelect(null)
      } catch (e) {
        toast('error', null, 'EditGroupForm/add-queue-error', e)
      }
    }
  }

  const onDeleteGroup = async () => {
    if (group) {
      try {
        setDeleteGroupLoading(true)
        await db.deleteGroup(store, group)
        setDeleteGroupLoading(false)
        deleteGroupDisclosure.onClose()
        onFinish()
      } catch (e) {
        setDeleteGroupLoading(false)
        toast('error', null, 'EditGroupForm/delete-group-error', e)
      }
    }
  }

  return (
    <Stack spacing={4}>
      <ConfirmAlert
        disclosure={deleteGroupDisclosure}
        headerText="Delete Group"
        questionText="Are you sure you want to delete the group?"
        noText="No"
        yesText="Yes"
        yesLoading={deleteGroupLoading}
        yesAction={onDeleteGroup}
      />
      {!groupLoading && group && (
        <Container>
          <Stack spacing={4}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <QRCode store={store} queueOrGroup={group} isGroup={true} />
            </Box>
            <FormControl id="groupId">
              <FormLabel>Group URL</FormLabel>
              <FormHelperText>The web address of the group.</FormHelperText>
              <Text>{`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/g/${group}`}</Text>
            </FormControl>
            <FormControl id="groupKioskUrl">
              <FormLabel>Group Kiosk URL</FormLabel>
              <FormHelperText>
                The web address of the group kiosk. The kiosk allows your customers to queue via an in-store tablet
                device (i.e. iPad).
              </FormHelperText>
              <Text>{`${process.env.REACT_APP_ENV === 'dev' ? 'https://dev.go.flowby.io' : 'https://go.flowby.io'}/${store}/g/${group}/kiosk`}</Text>
            </FormControl>
            <FormikForm
              fields={[
                {
                  type: 'textInput',
                  id: 'groupDisplayName',
                  label: 'Group Name',
                  initialValue: groupData?.displayName,
                  helperText: 'Display name of the group.',
                  validation: (value: string) => {
                    if (!value || value.length === 0) {
                      return 'Group name is required.'
                    }
                    if (value.length > 24) {
                      return 'Group name is too long. Max is 24 characters.'
                    }
                    return undefined
                  }
                }
              ]}
              inlineSubmitButton={true}
              submitButtonText="Save"
              onSubmit={async (values) => {
                await editGroupDisplayName(values)
              }}
            />
            <FormControl id="groupQueues">
              <FormLabel>Queues</FormLabel>
              <FormHelperText>
                The queues listed below will be presented to the user when visiting the group QR link.
              </FormHelperText>
              <Box my={2} borderWidth={1} borderRadius={'md'} borderColor={'gray.200'}>
                <Table data-testid="queues-in-group" variant="unstyled">
                  <Thead>
                    <Tr borderBottomWidth={1}>
                      <Th>Queues in group</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {queuesInGroup.map(q => {
                      return (
                        <Tr borderBottomWidth={1} key={q.shortName}>
                          <Td>
                            <Stack direction="row">
                              <Text>{q.displayName}</Text>
                              <Button
                                variant="outline"
                                size="xs"
                                onClick={() => {
                                  onRemoveQueue(q.shortName)
                                }}
                              >
                                Remove
                              </Button>
                            </Stack>
                          </Td>
                        </Tr>
                      )
                    })}
                    {queuesInGroup.length === 0 && (
                      <Tr borderBottomWidth={1}>
                        <Td>
                          <Stack direction="row">
                            <Text fontSize="sm" color="gray.500">
                              The group is empty. To add a queue select it and click the add button below.
                            </Text>
                          </Stack>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </Box>
              <Stack direction="row" alignItems="center">
                <Select
                  isDisabled={queuesNotInAGroup.length <= 0}
                  onChange={e => setAddQueueSelect(e.target.value)}
                  name="addQueueToGroup"
                >
                  {queuesNotInAGroup.map(queue => {
                    return (
                      <option key={queue.shortName} value={queue.shortName}>
                        {queue.displayName}
                      </option>
                    )
                  })}
                  {queuesNotInAGroup.length <= 0 && <option value={undefined}>No queues left to assign</option>}
                </Select>
                <Button
                  data-testid="add-queue-to-group-button"
                  size="md"
                  isDisabled={queuesNotInAGroup.length <= 0}
                  onClick={() => onAddQueue()}
                >
                  Add
                </Button>
              </Stack>
            </FormControl>
            <FormControl id="deleteGroup">
              <FormLabel>Delete Group</FormLabel>
              <Button
                data-testid="delete-group-button"
                colorScheme="red"
                size="sm"
                onClick={deleteGroupDisclosure.onOpen}
              >
                Delete Group
              </Button>
            </FormControl>
          </Stack>
        </Container>
      )}
    </Stack>
  )
}
