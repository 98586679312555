import { Box, Stack, Container, Text } from '@chakra-ui/react'

export default function StoreManualNumber({
  manualQueueNumber
}: {
  manualQueueNumber: number | null
}) {
  return (
    <Container>
      <Stack textAlign="center" align="center" py={{ base: 4, md: 8 }} spacing={4}>
        <Text fontSize="xl">Notify the customer of the queue number shown below before closing this screen.</Text>
        <Box bgColor="blue.200" borderRadius="0.375rem" px={8}>
          <Text fontWeight="bold" fontSize="9xl">
            {manualQueueNumber ? manualQueueNumber : '...'}
          </Text>
        </Box>
      </Stack>
    </Container>
  )
}
